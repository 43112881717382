import apiRequest from './libs/apiRequest';

export interface ILogin {
  username: string;
  password: string;
}

export interface IRegister {
  username: string
  password: string
  email: string
  organization: string
}

// 用户登录
export const login = (data: ILogin) => {
  return apiRequest.post({
    url: '/login',
    data,
  });
};

// 用户注册
export const register = (data: IRegister) => {
  return apiRequest.post({
    url: '/register',
    data,
  });
};
