var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-form',{staticClass:"register-form",attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'username',
        { rules: [{ required: true, message: 'Please input your username!' }] },
      ]),expression:"[\n        'username',\n        { rules: [{ required: true, message: 'Please input your username!' }] },\n      ]"}],attrs:{"data-test":"userNameInput","placeholder":"Username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        { rules: [
          { required: true, message: 'Please input your Password!' },
          { type: 'string', pattern: /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, message: 'At least 8 characters, and must contain letters and numbers,' },
          { validator: _vm.validateToNextPassword },
        ] },
      ]),expression:"[\n        'password',\n        { rules: [\n          { required: true, message: 'Please input your Password!' },\n          { type: 'string', pattern: /^(?=.*[a-zA-Z])(?=.*\\d)[a-zA-Z\\d]{8,}$/, message: 'At least 8 characters, and must contain letters and numbers,' },\n          { validator: validateToNextPassword },\n        ] },\n      ]"}],attrs:{"data-test":"passwordInput","type":"password","placeholder":"Password"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',{attrs:{"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'confirmPassword',
        { rules: [
          { required: true, message: 'Please confirm your Password!' },
          { validator: _vm.compareToFirstPassword },
        ] },
      ]),expression:"[\n        'confirmPassword',\n        { rules: [\n          { required: true, message: 'Please confirm your Password!' },\n          { validator: compareToFirstPassword },\n        ] },\n      ]"}],attrs:{"data-test":"confirmPasswordInput","type":"password","placeholder":"Confirm password"},on:{"blur":_vm.handleConfirmBlur}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'email',
        { rules: [
          {
            type: 'email',
            message: 'The input is not valid E-mail!'
          },
          {   
            required: true,
            message: 'Please input your email!' 
          }
        ] },
      ]),expression:"[\n        'email',\n        { rules: [\n          {\n            type: 'email',\n            message: 'The input is not valid E-mail!'\n          },\n          {   \n            required: true,\n            message: 'Please input your email!' \n          }\n        ] },\n      ]"}],attrs:{"data-test":"emailInput","placeholder":"Email"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'organization',
      ]),expression:"[\n        'organization',\n      ]"}],attrs:{"data-test":"organizationInput","placeholder":"Organization"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('div',{staticClass:"register-error"},[_vm._v(_vm._s(_vm.errorMessage))]),_c('a-button',{ref:"submitBtn",staticClass:"register-form-button",attrs:{"data-test":"registerBtn","type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" Register ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }