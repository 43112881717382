var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'username',
        { rules: [{ required: true, message: 'Please input your username!' }] },
      ]),expression:"[\n        'username',\n        { rules: [{ required: true, message: 'Please input your username!' }] },\n      ]"}],attrs:{"data-test":"userNameInput","placeholder":"Username"}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'password',
        { rules: [{ required: true, message: 'Please input your Password!' }] },
      ]),expression:"[\n        'password',\n        { rules: [{ required: true, message: 'Please input your Password!' }] },\n      ]"}],attrs:{"data-test":"passwordInput","type":"password","placeholder":"Password"},on:{"pressEnter":_vm.handleSubmit}},[_c('a-icon',{staticStyle:{"color":"rgba(0,0,0,.25)"},attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('div',{staticClass:"login-error"},[_vm._v(_vm._s(_vm.errorMessage))]),_c('a-button',{ref:"submitBtn",staticClass:"login-form-button",attrs:{"data-test":"loginBtn","type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" Log in ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }