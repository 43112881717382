
import { Component, Vue } from "vue-property-decorator";
import { login, ILogin } from "@/api/auth";
import storage from "@/utils/storage.ts";

@Component({})
export default class Login extends Vue {
  public form: any;
  public loading: boolean = false;
  public errorMessage: string = "";

  public beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "normal_login" });
  }
  public handleSubmit(): void {
    this.form.validateFields(async (err: object, values: ILogin) => {
      if (!err) {
        this.loading = true;
        try {
          const data = await login(values);
          if (data.status) {
            storage.localSet("version_user_info", data.users); // 保存用户信息
            this.$router.replace({ path: "/appList" });
            this.errorMessage = "";
            this.loading = false;
          } else {
            this.errorMessage = data.message;
            this.loading = false;
          }
        } catch {
          this.errorMessage = "";
          this.loading = false;
        }
      }
    });
  }
}
