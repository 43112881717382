
import { Component, Vue } from 'vue-property-decorator';
import { register, IRegister } from '@/api/auth';

interface IRegisterForm extends IRegister {
  confirmPassword: string
}

@Component({})
export default class Register extends Vue {
  public form: any;
  public loading: boolean = false;
  public errorMessage: string = '';
  public confirm: boolean = false;

  public beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: 'normal_register' });
  }
  public handleSubmit(): void {
    this.form.validateFields(async (err: object, values: IRegisterForm) => {
      if (!err) {
        this.loading = true;
        try {
          const registerData = {
            username: values.username,
            password: values.password,
            email: values.email,
            organization: values.organization
          }
          const data = await register(registerData);
          if (data.status) {
            this.errorMessage = '';
            this.$message.success('注册成功！');
            this.$emit('formChange');
            this.loading = false;
          } else {
            this.errorMessage = data.message;
            this.loading = false;
          }
        } catch {
          this.errorMessage = '';
          this.loading = false;
        }
      }
    });
  }
  public handleConfirmBlur(e: any) {
    const value = e.target.value;
    this.confirm = this.confirm || !!value;
  }
  public compareToFirstPassword(rule: object, value: string, callback: (val?: string) => void) {
    const form = this.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  }
  public validateToNextPassword(rule: object, value: string, callback: (val?: string) => void) {
    const form = this.form;
    if (value && this.confirm) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  }
}
