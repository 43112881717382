
import { Component, Vue } from 'vue-property-decorator';
import Login from './components/Login.vue';
import Register from './components/Register.vue';

@Component({
  components: {
    Login,
    Register
  }
})
export default class Index extends Vue {
  public isLogin: boolean = true;

  public formChange(): void {
    this.isLogin = !this.isLogin;
  }
}
